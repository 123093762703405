import React from "react";
import Footer from "../build/Footer";
import ProjectsHeader from "./ProjectsHeader";
import Projectm from "../build/Projectm";

const Projects = () => {
  return (
    <>
      <ProjectsHeader />
      <Projectm />
      <Footer />
    </>
  );
};

export default Projects;
